.iq-card {
	background: #ffffff;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	margin-bottom: 30px;
	border: none;

	box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
}
.iq-card-body {
	padding: 20px;
}

.iq-primary {
	background: #089bab !important;
	color: #ceebee !important;
}
.iq-bg-primary {
	background: #ceebee !important;
	color: #089bab !important;
}

.iq-warning {
	color: #fae9dd !important;
	background: #ffb177 !important;
}
.iq-bg-warning {
	background: #fae9dd !important;
	color: #ffb177 !important;
}
.iq-danger {
	color: #fcecec !important;
	background: #e64141 !important;
}
.iq-bg-danger {
	background: #fcecec !important;
	color: #e64141 !important;
}
.iq-info {
	color: #e5faff !important;
	background: #00d0ff !important;
}
.iq-bg-info {
	background: #e5faff !important;
	color: #00d0ff !important;
}
.iq-success {
	color: #d4edda !important;
	background: #28a745 !important;
}

.iq-bg-success {
	background: #d4edda !important;
	color: #28a745 !important;
}
.iq-secondary {
	color: #e2e3e5 !important;
	background: #6c757d !important;
}
.iq-bg-secondary {
	background: #e2e3e5 !important;
	color: #6c757d !important;
}

.iq-bg-dark {
	background: #343a40 !important;
	color: #adb5bd !important;
}

.iq-bg-light {
	background: #f8f9fa !important;
	color: #212529 !important;
}
.card-sec {
	padding: 10px 25px;
}
.chart-sec {
	background: white;
	border-radius: 15px;
	margin: 2%;
	/* width: 92%; */
	padding: 2%;
}
.excel-img,
.pdf-img {
	width: 25px;
}
.downloadAll {
	width: 20% !important;
}

.iq-card-icon {
	height: 60px;
	width: 60px;
	display: inline-block;
	line-height: 60px;
	text-align: center;
	font-size: 22px;
	border-radius: 50%;
}

.ant-spin .ant-spin-dot-item {
	background-color: white !important;
}
.ant-upload-wrapper .ant-upload-drag {
	background: #f3f2f2 !important;
}
.ant-upload-wrapper .ant-upload-drag .anticon svg {
	color: #225994;
}
.white_bg_sec {
	text-align: center;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

	background: #fff;
	border-radius: 15px;
	padding: 20px;
	margin: 15px;
}
.ant-upload-wrapper .ant-upload-drag .upload_btn {
	background: linear-gradient(135deg, #aec1d6, #7bbbff) !important ;
	color: #225994 !important;
}
.working__area .white_bg_sec .background-sec {
	background: #225994;
}
.working__area {
	text-align: center;
}
.working__area h1,
.working__area h3 {
	text-align: center;
	color: #225994;
}
.working__area .background-heading {
	margin: 10px 20px;
	text-align: center;
	border-radius: 20px;
	background: #225994;
	color: white;
}
.working__area .white_bg_sec .ant-upload-wrapper,
.ant-upload-list-item-container,
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item
	.ant-upload-list-item-actions
	.anticon,
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item
	.ant-upload-icon
	.anticon {
	color: #fff;
}
#loading {
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	position: fixed;
	display: block;
	opacity: 0.7;
	background-color: #fff;
	z-index: 99;
	text-align: center;
}

#loading-image {
	position: absolute;
	top: 100px;
	left: 240px;
	z-index: 100;
}

.blur-background {
	filter: blur(5px);
}

.countdown {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	font-weight: bold;
	color: white;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	z-index: 10;
}
.progress-bar-container {
	width: 100%;
	margin-top: 10px;
}

.progress-bar {
	height: 20px;
	border-radius: 4px;
	background-color: #f0f0f0;
	overflow: hidden;
}

.progress-bar-fill {
	height: 100%;
	background-color: #1890ff; /* Change color as needed */
	transition: width 0.3s ease;
}

.progress-bar-label {
	text-align: center;
	color: #000; /* Change color as needed */
}
.white_bg_sec .invoice__details span {
	color: #000;
	font-weight: bold;
}
/* .white_bg_sec .noData_div {
	min-height: 140px;
} */
