.App {
	text-align: center;
}
body {
	overflow: hidden;
}
.btn-primary {
	width: 40% !important;
	color: #ffffff;
	border-color: #089bab !important;
	background: linear-gradient(
		to right,
		rgb(30, 113, 202, 1) 0%,
		rgb(28, 92, 160, 1) 100%
	);
}
.btn-primary:hover {
	background: linear-gradient(
		to right,
		rgba(28, 92, 160, 1) 0%,
		rgba(30, 113, 202, 1) 100%
	);
}
.ant-btn-text:not(:disabled):not(.ant-btn-disabled) {
	color: rgba(0, 0, 0, 0.88) !important;
	background: rgba(0, 0, 0, 0.06) !important;
}
.upload_btn.ant-btn-primary {
	background: linear-gradient(135deg, #aec1d6, #7bbbff) !important ;
	color: #225994 !important;
}
.upload_btn.ant-btn-primary:hover {
	background: linear-gradient(135deg, #7bbbff, #aec1d6) !important ;
	color: white;
}
.upload_btn.ant-btn-primary:disabled {
	background: linear-gradient(135deg, #aec1d6, #7bbbff) !important ;
	color: #225994 !important;
}
.upload_btn.ant-btn-primary:disabled:hover {
	background: linear-gradient(135deg, #7bbbff, #aec1d6) !important ;
	color: white;
}
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item
	.ant-upload-list-item-action {
	opacity: 1 !important;
}

/* loader spin */

.ant-spin .ant-spin-dot-item {
	color: white !important;
	background-color: white !important;
}
.cancel-danger {
	background: #fcecec !important;
	color: #e64141 !important;
}
.App-container {
	width: 80%;
	margin-top: 6%;
	margin-left: 20%;
}
.main-container {
	/* margin-left: 260px; */
	overflow: hidden;
	padding: 10px;
	min-height: 100vh;
	transition: all 0.3s ease-out 0s;
	background: #eff7f8;
	/* border-radius: 25px 0 0 25px; */
}
.sign-up-page-bg {
	padding: 10px 123px;
}
.side__navigation {
	width: 20%;
	/* background: rgba(8, 155, 171, 1); */
	background: #225994;
	transition: all 0.5s;
}
.whole__section {
	display: flex;
}
.content__area {
	width: 80%;
}
.working__area {
	height: calc(100vh - 65px);
	overflow-y: scroll;
	overflow-x: hidden;
}
.background-sec {
	background: white;
	border-radius: 15px;
	margin: 2%;
	width: 92%;
	padding: 2%;
}
.working__area .head .ant-btn-primary,
.working__area .analyze_doc .ant-btn-primary {
	width: 20% !important;
}
.working__area .analyze_doc .ant-btn-primary.Previous,
.working__area .analyze_doc .ant-btn-primary.Next {
	border: 0pc;
	border-radius: 12px;
	box-shadow: rgba(34, 89, 148, 0.4) 5px 5px, rgba(34, 89, 148, 0.3) 10px 10px,
		rgba(34, 89, 148, 0.2) 15px 15px;
}
.ant-btn-primary {
	width: 40%;
	color: #ffffff;
	border-color: #225994 !important;
	/* background: linear-gradient(
		to right,
		rgba(8, 155, 171, 1) 0%,
		rgba(13, 181, 200, 1) 100%
	); */
	background: linear-gradient(
		to right,
		rgb(30, 113, 202, 1) 0%,
		rgb(28, 92, 160, 1) 100%
	);
}
.ant-btn-primary:hover {
	background: linear-gradient(
		to right,
		rgba(28, 92, 160, 1) 0%,
		rgba(30, 113, 202, 1) 100%
	) !important;
	border-color: #0db5c8;
	color: white;
}

/* inputFile section */

.background-sec {
	text-align: center;
}
button a.navLink {
	color: white !important;
}

/* Scroll bar design */

::-webkit-scrollbar {
	width: 5px;
	/* margin-left: 2px; */
	height: 2px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	margin-right: 5px;
}

::-webkit-scrollbar-thumb {
	background: #918b8b;
}

#sidebar.active {
	width: 6%;
	transition: all 0.5s;
}
#sidebar.active span {
	display: none;
}
#content.content_active {
	width: 100%;
	transition: all 0.5s;
}
@media screen and (max-width: 480px) {
	.iq-menu span {
		display: none;
	}
	.heading-das {
		display: none;
	}
	.iq-sidebar-logo span {
		font-size: 17px;
	}
}
@media screen and (max-width: 992px) {
	.iq-sidebar-logo span {
		font-size: 24px;
	}
}
